import { unstable_serialize } from 'swr'
import { NextPageContext } from 'next'

import { ShopDetail } from '~/model/shop'
import Category from '~/model/category'
import News from '~/model/news'
import User from '~/model/user'
import Product from '~/model/product'

import {
  CollectionProductsListResponse,
  fetchCollectionProductsList,
  fetchPickupProductsList,
  fetchRankingProductList,
  fetchTokubaiProductList,
  ShopPickupProductsListResponse
} from '~/common/api/product'
import { ApiPath, fetchApiList } from '~/common/api/base'
import { topPageTitle } from '~/common/app/title'
import { fetchShopList, ShopListResponse } from '~/common/api/shop'
import { fetchCategoryList } from '~/common/api/category'
import { CarouselResponse, fetchCarousel } from '~/common/api/carousel'
import { fetchNewsList } from '~/common/api/news'
import { fetchUser, useMoveByUserShopArea } from '~/common/api/user'
import MetaHead from '~/components/app/layout/MetaHead'
import TopPage from '~/components/app/page/top/TopPage'

const Page = () => {
  useMoveByUserShopArea()

  return (
    <>
      <MetaHead title={topPageTitle()} isTop />
      <TopPage />
    </>
  )
}

export const getServerSideProps = async ({ req }: NextPageContext) => {
  console.time('Master')

  const [
    user,
    shops,
    categories,
    newses,
    carousel,
    tokubaiProducts,
    pickupProducts,
    rankingProducts,
    collectionProducts
  ] = await fetchApiList<
    | User
    | ShopDetail[]
    | Category[]
    | CarouselResponse
    | News[]
    | Product[]
    | ShopPickupProductsListResponse[]
    | CollectionProductsListResponse[]
  >([
    fetchUser(req?.headers.cookie),
    fetchShopList(),
    fetchCategoryList(),
    fetchNewsList(),
    fetchCarousel(),
    fetchTokubaiProductList(),
    fetchPickupProductsList(),
    fetchRankingProductList(),
    fetchCollectionProductsList()
  ])

  console.timeEnd('Master')

  if (user && (user as User).access_area_key) {
    return {
      redirect: {
        permanent: false,
        destination: '/' + (user as User).access_area_key
      }
    }
  }

  return {
    props: {
      fallback: {
        [unstable_serialize([ApiPath.SHOP_LIST, {}])]: { shops } as ShopListResponse,
        [unstable_serialize([ApiPath.CATEGORY_LIST, {}])]: categories,
        [unstable_serialize([ApiPath.NEWS_LIST, {}])]: newses,
        [unstable_serialize([ApiPath.CAROUSEL, {}])]: carousel,
        [unstable_serialize([ApiPath.PRODUCT_TOKUBAI_LIST, {}])]: tokubaiProducts,
        [unstable_serialize([ApiPath.PRODUCT_PICKUP_LIST, {}])]: pickupProducts,
        [unstable_serialize([ApiPath.PRODUCT_RANKING_LIST, {}])]: rankingProducts,
        [unstable_serialize([ApiPath.PRODUCT_COLLECTION_LIST, {}])]: collectionProducts
      }
    }
  }
}

export default Page
